import { useMyContext } from '../../../contexts/StateHolder';
//import { useState } from "react";
import { useTranslation } from 'react-i18next';
import { convertDate } from '../../../scripts/utils';
//import { changePassword, updateUser } from "../../scripts/dataHandlers";
import ProfileHeader from '../ProfileHeader';
import * as classes from '../../../Shared/Input/Input.module.css';
import * as classes2 from './CurrentSubscription.module.css';

const CurrentSubscription = (props) => {
  // Get user from main profile component
  const user = props.user;

  // Setup translate function
  const { t } = useTranslation();

  let svodArray = [];
  let tvodArray = [];

  // If user has buyerProducts, seprate products into different arrays including subscriptions and (rental) products
  if (user?.buyerProducts?.length > 0) {
    svodArray = user.buyerProducts.filter((product) =>
      product.sku.includes('SV')
    );
    tvodArray = user.buyerProducts.filter((product) =>
      product.sku.includes('TV')
    );
  }

  // If user has any buyerProducts, show contents of subscriptions view. Otherwise show empty placeholder ("You have no subscriptions / products")
  return user?.buyerProducts?.length > 0 ? (
    <div className={classes.formProfilePrimary}>
      <div className={classes.formSecondary}>
        <form className={classes.formBlog}>
          <ProfileHeader showSubscriptions={props.showSubscriptions} />

          <div className={classes2.title}>{`${t(
            'subscriptions.subTitle'
          )}:`}</div>

          {/* If user has any subscriptions, show them. Otherwise show you have no subscriptions */}
          {svodArray.length > 0 ? (
            svodArray.map((product, i) => (
              <div className={classes2.item} key={i}>
                <div className={`${classes2.itemName} font-400`}>
                  {product.name}
                </div>
                <div className={classes2.inner}>
                  <div className={classes2.subTitle}>{`${t(
                    'subscriptions.activeTill'
                  )}:`}</div>
                  <div className={classes2.dateRow}>
                    {convertDate(product.validFrom)}
                    <div className={classes2.separator}>-</div>
                    {convertDate(product.validTo)}
                  </div>
                  <div className={classes2.subTitle}>{`${t(
                    'subscriptions.nextPayment'
                  )}:`}</div>
                  {convertDate(product.nextPaymentDate)}
                </div>
              </div>
            ))
          ) : (
            <div className={classes2.item}>
              <div className={`${classes2.itemName} font-400`}>
                {t('subscriptions.youHaveNoSubscriptions')}
              </div>
            </div>
          )}

          <div className={classes2.tvodTitleRow}>
            <div className={classes2.title}>{`${t(
              'subscriptions.assetTitle'
            )}:`}</div>
            <div className={classes2.dateTitle}>{t('subscriptions.date')}</div>
          </div>

          {/* If user has any tvod products, show them. Otherwise show you have no products */}
          {tvodArray.length > 0 ? (
            tvodArray.map((product, i) => (
              <div className={`${classes2.tvodItemRow} font-400`} key={i}>
                <div>{product.name} </div>
                <div>{convertDate(product.validFrom)}</div>
              </div>
            ))
          ) : (
            <div className={`${classes2.tvodItemRow} font-400`}>
              <div>{t('subscription.youHaveNoProducts')} </div>
              <div>{''}</div>
            </div>
          )}
        </form>
      </div>
    </div>
  ) : (
    <div className={classes.formProfilePrimary}>
      <div className={classes.formSecondary}>
        <form className={classes.formBlog}>
          <ProfileHeader showSubscriptions={props.showSubscriptions} />
          <div className={classes2.placeholder}>
            {t('subscriptions.placeholder')}
          </div>
        </form>
      </div>
    </div>
  );
};

export default CurrentSubscription;
