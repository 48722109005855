import { useMyContext } from '../../contexts/StateHolder';
import LiveEventPlayer from './LiveEventPlayer';
import { useEffect, useState } from 'react';
import settings from '../../config_settings.json';
import { useCookies } from 'react-cookie';
import { useMyContextFunctions } from '../../contexts/ContextFunctions';

const LiveVideoPlayer = (props) => {
  // Bring stateholders from context
  const { key, organizationId, liveChannelPrivate } = useMyContext();

  const [channelServiceId, setChannelServiceId] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [companyId, setCompanyId] = useState(null);

  const { redirectUserToLogin } = useMyContextFunctions();
  const [cookies] = useCookies('');
  const [showPlayer, setShowPlayer] = useState('hidden');

  console.log(cookies);

  useEffect(() => {
    if (organizationId) {
      setGroupId(settings.organization.groupId);
      setChannelServiceId(
        props.channelServiceId || settings.organization.channelServiceId
      );
      setCompanyId(settings.organization.companyId);
      if (showPlayer === 'hidden' || showPlayer === 'wantedHidden') {
        setShowPlayer('visible');
      } else {
        setShowPlayer('hidden');
      }
    }
    return () => {
      setShowPlayer('hidden');
    };
  }, [organizationId, props.channelServiceId]);

  useEffect(() => {
    if (liveChannelPrivate) {
      !cookies.userData.userId && redirectUserToLogin('login');
    }
  }, [redirectUserToLogin, cookies.userData, liveChannelPrivate]);
  console.log('visible');
  if (
    key &&
    channelServiceId &&
    companyId &&
    showPlayer === 'visible' &&
    props.eventId
  ) {
    return (
      <div className='maxContainerPrimary'>
        <LiveEventPlayer
          companyId={companyId}
          groupId={groupId} // Group of organization
          organizationId={organizationId} // Id of organization
          playerAutoStart={props.playerAutoStart}
          secretKey={key} // Secret key for organization
          channelServiceId={channelServiceId} // channelServiceId for live video
          backRoute={props.backRoute} // Route, where back button takes user. If undefined, it takes automatically to previous route
          //key={assetId} // For react's re-rendering purposes
          userId={liveChannelPrivate ? cookies.userData.userId : 0}
          // to check if the live player is playing live events , we pass as true if it  is playing live events ffor sending different analytics
          eventPlayer={props.eventPlayer}
          eventId={props.eventId}
        />
      </div>
    );
  } else if (
    !props.eventId &&
    organizationId !== '' &&
    showPlayer === 'wantedHidden'
  ) {
    return (
      <div className='showPlayerBar'>
        <button
          className='showPlayerBTN'
          onClick={() => setShowPlayer('visible')}
        >
          {'videoPlayer.showPlayer'}
        </button>
      </div>
    );
  } else {
    return null;
  }
};

export default LiveVideoPlayer;
