import { useMyContext } from '../../contexts/StateHolder';
import { useTranslation } from 'react-i18next';
import ChangePassword from './ChangePassword/ChangePassword';
import UserDetails from './UserDetails/UserDetails';
import CurrentSubscription from './Subscriptions/CurrentSubscription';
import * as classes from './Profile.module.css';
import { useCookies } from 'react-cookie';

const Profile = (props) => {
  const { fields, showSubscriptions } = props.settings;

  const { chosenMenuOptionProfile } = useMyContext();
  const [cookies] = useCookies('');

  const { t } = useTranslation();

  const renderOptions = () => {
    if (chosenMenuOptionProfile === 'userDetails') {
      return (
        <UserDetails fields={fields} showSubscriptions={showSubscriptions} />
      );
    } else if (chosenMenuOptionProfile === 'subscriptions') {
      return <CurrentSubscription showSubscriptions={showSubscriptions} />;
    } else if (chosenMenuOptionProfile === 'changePassword') {
      return <ChangePassword showSubscriptions={showSubscriptions} />;
    }
  };

  if (cookies.userData) {
    return (
      <div className={classes.profileContainer}>
        <div className={classes.profileOptionView}>
          <div>{renderOptions()}</div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={classes.profileContainer}>
        <div className={classes.profileMessage}>
          {t('profileMenu.loginFirst')}
        </div>
      </div>
    );
  }
};

export default Profile;
