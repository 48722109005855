import React from 'react';
import { getImageByKey } from '../../scripts/getImageByKey';
import * as classes from './ContactUs.module.css';

export default function ContactUs() {
  return (
    <div className={classes.ContactUs}>
      <div className={classes.ContactUsPrimary}>
        <div className={classes.ContactUs_image}>
          <img src={getImageByKey('contactus')} alt='Digital Tree' />
        </div>
        <div className={classes.ContactUs_description}>
          <div className={classes.ContactUs_description_Primary}>
            <h4 className='font-700'>Contact Us</h4>
            <div className={classes.ContactUs_description_address}>
              <div
                className={`${classes.ContactUs_description_address_info} font-400`}
              >
                <div
                  className={classes.ContactUs_description_address_info_title}
                ></div>
                <div>-----------</div>
                <div>-----------</div>
                <div>-----------</div>
              </div>
            </div>
            <div className={classes.ContactUs_description_phone}>
              <div className={classes.ContactUs_description_address_info_title}>
                {' '}
                Phone
              </div>
              <div>+264 00 00 00</div>
            </div>
            <div className={classes.ContactUs_description_email}>
              <a
                className={classes.ContactUs_description_email_button}
                href='mailto:info@tree.com.cy
'
              >
                Send Email
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
