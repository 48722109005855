import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import * as classes from '../NavBars/NavBarButton.module.css';

import { useMyContext } from '../../contexts/StateHolder';

const Login = (props) => {
  const { user, setUser, closeHamMenu } = useMyContext();

  const { t } = useTranslation();

  const [cookies, removeCookie] = useCookies('');

  const logOut = () => {
    closeHamMenu();

    let usr = { ...user };

    usr = {};

    setUser(usr);

    if (cookies?.userData) {
      setTimeout(() => {
        removeCookie('userData', {
          path: '/',
          domain: 'http://localhost:3000/',
        });

        console.log(cookies?.userData);
      }, 100);
    }
  };

  return (
    <div className='loginContainer'>
      {user.loggedIn ? (
        <>
          <NavLink
            className='loginBTN'
            activeClassName={classes.activeSelected}
            onClick={closeHamMenu}
            to={`/${props.routes.profile}`}
          >
            {user.loggedIn
              ? t('login.profileButton')
              : t('login.profileButton')}
          </NavLink>
          {!props.hideLogoutSmallScreen && (
            <div className='navBarSiteLogoMobile-lgScreen'>
              <div className='' style={{ marginRight: '5px' }}>
                {'| '}
              </div>

              <NavLink
                className='loginBTN'
                activeClassName={classes.activeSelected}
                onClick={() => {
                  logOut();
                }}
                to={`/${props.routes.login}`}
              >
                {user.loggedIn
                  ? t('login.logOutButton')
                  : t('login.loginButton')}
              </NavLink>
            </div>
          )}
        </>
      ) : (
        <NavLink
          className='loginBTN'
          activeClassName={classes.activeSelected}
          onClick={closeHamMenu}
          to={`/${props.routes.login}`}
        >
          {user.loggedIn ? t('login.logOutButton') : t('login.loginButton')}
        </NavLink>
      )}
    </div>
  );
};

export default Login;
