import * as classes from './TOS.module.css';
//import { useTranslation } from 'react-i18next';
import { BrowserRouterasRouter } from 'react-router-dom';

const TOS = (props) => {
  //const { t } = useTranslation();

  return (
    <div
      className={`${classes.tosContainer} font-300`}
      style={props?.styles?.tosContaine}
    >
      <div
        className={`${classes.tosMainTitle} font-600 ${classes.tosMainTitle_bold_800}`}
      >
        DTMEDIA TERMS AND CONDITIONS
      </div>
      <br />
      <hr />
      <br />
      <div className={`${classes.tosMainTitle}  font-400 `}>
        AGREEMENT TO TERMS
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        {' '}
        These Terms and Conditions constitute a legally binding agreement made
        between you, whether personally or on behalf of an entity (“you”) and
        [DTMEDIA] (“we,” “us” or “our”), concerning your access to and use of
        the https://dtmedia.com.na/ as well as any other media form, media
        channel, mobile website or mobile application related, linked, or
        otherwise connected thereto (collectively, DTMEDIA – Premium Online
        Media).
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        You agree that by accessing the DTMEDIA Website, you have read,
        understood, and agree to be bound by all of these Terms and Conditions.
        If you do not agree with all of these Terms and Conditions, then you are
        expressly prohibited from using the DTMEDIA Website and you must
        discontinue use immediately.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Supplemental terms and conditions or documents that may be posted on the
        DTMEDIA Website from time to time are hereby expressly incorporated
        herein by reference. We reserve the right, in our sole discretion, to
        make changes or modifications to these Terms and Conditions at any time
        and for any reason.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        We will alert you about any changes by updating the “Last updated” date
        of these Terms and Conditions, and you waive any right to receive
        specific notice of each such change.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        It is your responsibility to periodically review these Terms and
        Conditions to stay informed of updates. You will be subject to, and will
        be deemed to have been made aware of and to have accepted, the changes
        in any revised Terms and Conditions by your continued use of the DTMEDIA
        Website after the date such revised Terms and Conditions are posted.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        The information provided on the DTMEDIA Website is not intended for
        distribution to or use by any person or entity in any jurisdiction or
        country where such distribution or use would be contrary to law or
        regulation or which would subject us to any registration requirement
        within such jurisdiction or country.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Accordingly, those persons who choose to access the DTMEDIA Website from
        other locations do so on their own initiative and are solely responsible
        for compliance with local laws, if and to the extent local laws are
        applicable.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        The DTMEDIA Website is intended for users who are 18-years old and
        above. Persons under the age of 18 are not permitted to register for the
        DTMEDIA Website. All users who are minors in the jurisdictions in which
        they reside (under the age of 18) must have the permission of, and be
        directly supervised by, their parent or guardian to use the DTMEDIA
        Website. If you are a minor, you must have your parent or guardian read
        and agree to these Terms and Conditions prior to you using the DTMEDIA
        Website.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400 `}>
        INTELLECTUAL PROPERTY RIGHTS
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Unless otherwise indicated, the DTMEDIA Website is our proprietary
        property and all source code, databases, functionality, software,
        DTMEDIA Website designs, audio, video, text, photographs, and graphics
        on the DTMEDIA Website (collectively, the “Content”) and the trademarks,
        service marks, and logos contained therein (the “Marks”) are owned or
        controlled by us or licensed to us, and are protected by copyright and
        trademark laws and various other intellectual property rights and unfair
        competition laws of the Republic of Namibia, foreign jurisdictions, and
        international conventions.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        The Content and the Marks are provided on the DTMEDIA Website “AS IS”
        for your information and personal use only. Except as expressly provided
        in these Terms and Conditions, no part of the DTMEDIA Website and no
        Content or Marks may be copied, reproduced, aggregated, republished,
        uploaded, posted, publicly displayed, encoded, translated, transmitted,
        distributed, sold, licensed, or otherwise exploited for any commercial
        purpose whatsoever, without our express prior written permission.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Provided that you are eligible to use the DTMEDIA Website, you are
        granted a limited license to access and use the DTMEDIA Website and to
        download or print a copy of any portion of the Content to which you have
        properly gained access solely for your personal, non-commercial use. We
        reserve all rights not expressly granted to you in and to the DTMEDIA
        Website, the Content and the Marks.
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400 `}>
        USER REPRESENTATIONS
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        By using the DTMEDIA Website, you represent and warrant that:
      </p>
      <br />
      <div className={classes.padded}>
        <ol>
          <li>
            all registration information you submit will be true, accurate,
            current, and complete.
          </li>
          <li>
            you will maintain the accuracy of such information and promptly
            update such registration information as and when necessary
          </li>

          <li>
            you have the legal capacity and you agree to comply with these Terms
            and Conditions
          </li>

          <li> you are not under the age of 18</li>

          <li>
            not a minor in the jurisdiction in which you reside [or if a minor,
            you have received parental permission to use the DTMEDIA Website]
          </li>

          <li>
            you will not access the DTMEDIA Website through automated or
            non-human means, whether through a bot, script, or otherwise
          </li>

          <li>
            you will not use the DTMEDIA Website for any illegal or unauthorized
            purpose
          </li>

          <li>
            your use of the DTMEDIA Website will not violate any applicable law
            or regulation in your jurisdiction.
          </li>
        </ol>
      </div>
      <br />
      If you provide any information that is untrue, inaccurate, not current, or
      incomplete, we have the right to suspend or terminate your account and
      refuse any and all current or future use of the DTMEDIA Website (or any
      portion thereof).
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400 `}>USER REGISTRATION</div>
      <p className={`${classes.tosMainTitle_p} `}>
        You may be required to register with the DTMEDIA Website. You agree to
        keep your password confidential and will be responsible for all use of
        your account and password. We reserve the right to remove, reclaim, or
        change a username you select if we determine, in our sole discretion,
        that such username is inappropriate, obscene, or otherwise
        objectionable.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ONLINE MEDIA, VIDEOS AND AUDIOS
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We make every effort to display as accurately as possible all the
        information, specifications and details of the online media, videos and
        audio available on the DTMEDIA Website. However, we do not guarantee
        that the information, specifications, and details of the online media,
        videos and audio will be accurate, complete, reliable, current, or free
        of other errors, and your electronic device or display may not
        accurately reflect the details of the products.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        All online media, videos and audio are subject to availability and
        displayed prices for all online media, videos and audio are subject to
        change.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        PURCHASES AND PAYMENT
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We accept MASTERCARD / VISA Debit or Credit Cards as forms of payment.
        You agree to provide current, complete, and accurate purchase and
        account information for all purchases made via the DTMEDIA Website. You
        further agree to promptly update account and payment information,
        including email address, payment method, and payment card expiration
        date, so that we can complete your transactions and contact you as
        needed. VAT and sales taxes will be added to the price of purchases as
        and when deemed required by us. We may change prices at any time.
        Payments maybe in U.S. Dollars, Euros, UK Pounds, Namibian Dollars,
        South African Rand or any other currency that we consider as
        appropriate.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        You agree to pay all charges at the prices then in effect for your
        purchases and any applicable fees, and you authorize us to charge your
        chosen payment provider for any such amounts upon placing your order and
        transaction.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        If your order is subject to recurring charges, then you consent to our
        charging your payment method on a recurring basis without requiring your
        prior approval for each recurring charge, until such time as you cancel
        the applicable order. We reserve the right to correct any errors or
        mistakes in pricing, even if we have already requested or received
        payment.
      </p>
      <p className={`${classes.tosMainTitle_p} `}>
        We reserve the right to refuse any order placed through the Site. We
        may, in our sole discretion, limit or cancel quantities purchased per
        person, per household, or per order. These restrictions may include
        orders placed by or under the same customer account, the same payment
        method, and/or orders that use the same billing address. We reserve the
        right to limit or prohibit orders that, in our sole judgment, appear to
        be placed by dealers, resellers, or distributors.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        RETURN OR REFUND POLICY
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Since all the products offered and made available on the DTMEDIA Website
        are online media, videos and audios, all successful transactions are
        final and no refund will be issued.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        PROHIBITED ACTIVITIES
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        As a user of the DTMEDIA Website, you agree not to:
      </p>
      <br />
      <div className={classes.padded}>
        <ol>
          <li>
            systematically retrieve data or other content from the DTMEDIA
            Website to create or compile, directly or indirectly, a collection,
            compilation, database, or directory without written permission from
            us.
          </li>
          <li>
            make any unauthorized use of the DTMEDIA Website, including
            collecting usernames and/or email addresses of users by electronic
            or other means for the purpose of sending unsolicited email, or
            creating user accounts by automated means or under false pretenses.
          </li>

          <li>
            use a buying agent or purchasing agent to make purchases on the
            DTMEDIA Website.
          </li>
          <li>
            use the DTMEDIA Website to advertise or offer to sell goods and
            services.
          </li>

          <li>
            {' '}
            circumvent, disable, or otherwise interfere with security-related
            features of the DTMEDIA Website, including features that prevent or
            restrict the use or copying of any Content or enforce limitations on
            the use of the DTMEDIA Website and/or the Content contained therein.
          </li>

          <li>
            engage in unauthorized framing of or linking to the DTMEDIA Website.
          </li>

          <li>
            trick, defraud, or mislead us and other users, especially in any
            attempt to learn sensitive account information such as user
            passwords;
          </li>

          <li>
            make improper use of our support services or submit false reports of
            abuse or misconduct.
          </li>

          <li>
            engage in any automated use of the system, such as using scripts to
            send comments or messages, or using any data mining, robots, or
            similar data gathering and extraction tools.
          </li>
          <li>
            interfere with,disrupt,or create an undue burden on the DTMEDIA
            Website or the networks or services connected to the DTMEDIA
            Website.
          </li>
          <li>
            attempt to impersonate another user or person or use the username of
            another user.
          </li>
          <li>sell or otherwise transfer your profile.</li>
          <li>
            use any information obtained from the DTMEDIA Website in order to
            harass,abuse,or harm another person.
          </li>
          <li>
            use the DTMEDIA Website as part of any effort to compete with us or
            otherwise use the DTMEDIA Website and/or the Content for any
            revenue-generating endeavor or commercial enterprise.
          </li>
          <li>
            decipher, decompile, disassemble, or reverse engineer any of the
            software comprising or in any way making up a part of the DTMEDIA
            Website.
          </li>
          <li>
            attempt to bypass any measures of the DTMEDIA Website designed to
            prevent or restrict access to the DTMEDIA Website, or any portion of
            the DTMEDIA Website.
          </li>
          <li>
            harass, annoy, intimidate, or threaten any of our employees or
            agents engaged in providing any portion of the DTMEDIA Website to
            you.
          </li>
          <li>
            delete the copyright or other proprietary rights notice from any
            Content.
          </li>
          <li>
            copy or adapt the DTMEDIA Website’s software, including but not
            limited to Flash, PHP, HTML, JavaScript, or other code.
          </li>
          <li>
            upload or transmit (or attempt to upload or to transmit) viruses,
            Trojan horses, or other material, including excessive use of capital
            letters and spamming (continuous posting of repetitive text), that
            interferes with any party’s uninterrupted use and enjoyment of the
            DTMEDIA Website or modifies, impairs, disrupts, alters, or
            interferes with the use, features, functions, operation, or
            maintenance of the DTMEDIA Website.
          </li>
          <li>
            upload or transmit (or attempt to upload or to transmit) any
            material that acts as a passive or active information collection or
            transmission mechanism, including without limitation, clear graphics
            interchange formats (“gifs”), 1×1 pixels, web bugs, cookies, or
            other similar devices (sometimes referred to as “spyware” or
            “passive collection mechanisms” or “pcms”).
          </li>
          <li>
            except as may be the result of standard search engine or Internet
            browser usage, use, launch, develop, or distribute any automated
            system, including without limitation, any spider, robot, cheat
            utility, scraper, or offline reader that accesses the DTMEDIA
            Website, or using or launching any unauthorized script or other
            software.
          </li>
          <li>
            disparage, tarnish, or otherwise harm, in our opinion, us and/or the
            DTMEDIA Website.
          </li>
          <li>
            use the DTMEDIA Website in a manner inconsistent with any applicable
            laws or regulations.
          </li>
        </ol>
      </div>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        USER GENERATED CONTRIBUTIONS
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        The DTMEDIA Website may invite you to chat, contribute to, or
        participate in blogs, message boards, online forums, and other
        functionality, and may provide you with the opportunity to create,
        submit, post, display, transmit, perform, publish, distribute, or
        broadcast content and materials to us or on the DTMEDIA Website,
        including but not limited to text, writings, video, audio, photographs,
        graphics, comments, suggestions, or personal information or other
        material (collectively, "Contributions").
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Contributions may be viewable by other users of the DTMEDIA Website and
        through third-party or client websites. As such, any Contributions you
        transmit may be treated as non-confidential and non-proprietary. When
        you create or make available any Contributions, you thereby represent
        and warrant that:
      </p>
      <br />
      <div className={classes.padded}>
        <ol>
          <li>
            the creation, distribution, transmission, public display, or
            performance, and the accessing, downloading, or copying of your
            Contributions do not and will not infringe the proprietary rights,
            including but not limited to the copyright, patent, trademark, trade
            secret, or moral rights of any third party.
          </li>
          <li>
            you are the creator and owner of or have the necessary licenses,
            rights, consents, releases, and permissions to use and to authorize
            us, the DTMEDIA Website, and other users of the DTMEDIA Website to
            use your Contributions in any manner contemplated by the DTMEDIA
            Website and these Terms and Conditions.
          </li>

          <li>
            you have the written consent, release, and/or permission of each and
            every identifiable individual person in your Contributions to use
            the name or likeness of each and every such identifiable individual
            person to enable inclusion and use of your Contributions in any
            manner contemplated by the DTMEDIA Website and these Terms and
            Conditions.
          </li>

          <li> your Contributions are not false, inaccurate, or misleading.</li>

          <li>
            your Contributions are not unsolicited or unauthorized advertising,
            promotional materials, pyramid schemes, chain letters, spam, mass
            mailings, or other forms of solicitation.{' '}
          </li>

          <li>
            your Contributions are not obscene, lewd, lascivious, filthy,
            violent, harassing, libelous, slanderous, or otherwise objectionable
            (as determined by us).
          </li>

          <li>
            your Contributions do not ridicule, mock, disparage, intimidate, or
            abuse anyone.
          </li>

          <li>
            your Contributions do not advocate the violent overthrow of any
            government or incite, encourage, or threaten physical harm against
            another.
          </li>
          <li>
            your Contributions do not violate any applicable law, regulation, or
            rule.
          </li>
          <li>
            your Contributions do not violate the privacy or publicity rights of
            any third party.
          </li>
          <li>
            your Contributions do not contain any material that solicits
            personal information from anyone under the age of 18 or exploits
            people under the age of 18 in a sexual or violent manner.
          </li>
          <li>
            your Contributions do not violate any federal or state law
            concerning child pornography, or otherwise intended to protect the
            health or well-being of minors;
          </li>

          <li>
            your Contributions do not include any offensive comments that are
            connected to race, national origin, gender, sexual preference, or
            physical handicap.
          </li>
          <li>
            your Contributions do not otherwise violate, or link to material
            that violates, any provision of these Terms and Conditions, or any
            applicable law or regulation.
          </li>
        </ol>
      </div>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Any use of the DTMEDIA Website in violation of the foregoing violates
        these Terms and Conditions and may result in, among other things,
        termination or suspension of your rights to use the DTMEDIA Website.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>CONTRIBUTION LICENSE</div>
      <p className={`${classes.tosMainTitle_p} `}>
        By posting your Contributions to any part of the DTMEDIA Website [or
        making Contributions accessible to the DTMEDIA Website by linking your
        account from the DTMEDIA Website to any of your social networking
        accounts], you automatically grant, and you represent and warrant that
        you have the right to grant, to us an unrestricted, unlimited,
        irrevocable, perpetual, non-exclusive, transferable, royalty-free,
        fully-paid, worldwide right, and license to host, use, copy, reproduce,
        disclose, sell, resell, publish, broadcast, retitle, archive, store,
        cache, publicly perform, publicly display, reformat, translate,
        transmit, excerpt (in whole or in part), and distribute such
        Contributions (including, without limitation, your image and voice) for
        any purpose, commercial, advertising, or otherwise, and to prepare
        derivative works of, or incorporate into other works, such
        Contributions, and grant and authorize sublicenses of the foregoing. The
        use and distribution may occur in any media formats and through any
        media channels.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        This license will apply to any form, media, or technology now known or
        hereafter developed, and includes our use of your name, company name,
        and franchise name, as applicable, and any of the trademarks, service
        marks, trade names, logos, and personal and commercial images you
        provide. You waive all moral rights in your Contributions, and you
        warrant that moral rights have not otherwise been asserted in your
        Contributions.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        We do not assert any ownership over your Contributions. You retain full
        ownership of all of your Contributions and any intellectual property
        rights or other proprietary rights associated with your Contributions.
        We are not liable for any statements or representations in your
        Contributions provided by you in any area on the DTMEDIA Website.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        You are solely responsible for your Contributions to the DTMEDIA Website
        and you expressly agree to exonerate us from any and all responsibility
        and to refrain from any legal action against us regarding your
        Contributions.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        We have the right, in our sole and absolute discretion, (1) to edit,
        redact, or otherwise change any Contributions; (2) to re-categorize any
        Contributions to place them in more appropriate locations on the DTMEDIA
        Website; and (3) to pre-screen or delete any Contributions at any time
        and for any reason, without notice. We have no obligation to monitor
        your Contributions.
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        GUIDELINES FOR REVIEWS
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We may provide you areas on the DTMEDIA Website to leave reviews or
        ratings. When posting a review, you must comply with the following
        criteria:
      </p>
      <div className={classes.padded}>
        <ol>
          <li>
            you should have firsthand experience with the person/entity being
            reviewed;
          </li>

          <li>
            your reviews should not contain offensive profanity, or abusive,
            racist, offensive, or hate language;
          </li>

          <li>
            your reviews should not contain discriminatory references based on
            religion, race, gender, national origin, age, marital status, sexual
            orientation, or disability;
          </li>

          <li>
            your reviews should not contain references to illegal activity;
          </li>

          <li>
            you should not be affiliated with competitors if posting negative
            reviews;
          </li>

          <li>
            you should not make any conclusions as to the legality of conduct;
          </li>
          <li>you may not post any false or misleading statements;</li>
          <li>
            you may not organize a campaign encouraging others to post reviews,
            whether positive or negative.
          </li>
        </ol>
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We may accept, reject, or remove reviews in our sole discretion. We have
        absolutely no obligation to screen reviews or to delete reviews, even if
        anyone considers reviews objectionable or inaccurate. Reviews are not
        endorsed by us, and do not necessarily represent our opinions or the
        views of any of our employees, agents, affiliates or partners.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        We do not assume liability for any review or for any claims,
        liabilities, or losses resulting from any review. By posting a review,
        you hereby grant to us a perpetual, non-exclusive, worldwide, royalty-
        free, fully-paid, assignable, and sub-licensable right and license to
        reproduce, modify, translate, transmit by any means, display, perform,
        and/or distribute all content relating to reviews.
      </p>
      <br />
      <br />
      <div className={`${classes.tosTitle} font-400`}>SUBMISSIONS</div>
      <p className={`${classes.tosMainTitle_p} `}>
        You acknowledge and agree that any questions, comments, suggestions,
        ideas, feedback, or other information regarding the DTMEDIA Website
        ("Submissions") provided by you to us are non-confidential and shall
        become our sole property. We shall own exclusive rights, including all
        intellectual property rights, and shall be entitled to the unrestricted
        use and dissemination of these Submissions for any lawful purpose,
        commercial or otherwise, without acknowledgment or compensation to you.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        You hereby waive all moral rights to any such Submissions, and you
        hereby warrant that any such Submissions are original with you or that
        you have the right to submit such Submissions. You agree there shall be
        no recourse against us for any alleged or actual infringement or
        misappropriation of any proprietary right in your Submissions.
      </p>{' '}
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        THIRD-PARTY DTMEDIAWEBSITES AND CLIENT CONTENT
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        The DTMEDIA Website may contain (or you may be sent via the DTMEDIA
        Website) links to other websites ("Third-Party Websites") as well as
        articles, photographs, text, graphics, pictures, designs, music, sound,
        video, information, applications, software, and other content or items
        belonging to or originating from third parties ("Third-Party Content").
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Such Third-Party Websites and Third-Party Content are not investigated,
        monitored, or checked for accuracy, appropriateness, or completeness by
        us, and we are not responsible for any Third- Party Websites accessed
        through the DTMEDIA Website or any Third-Party Content posted on,
        available through, or installed from the DTMEDIA Website, including the
        content, accuracy, offensiveness, opinions, reliability, privacy
        practices, or other policies of or contained in the Third- Party
        Websites or the Third-Party Content.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Inclusion of, linking to, or permitting the use or installation of any
        Third-Party Websites or any Third-Party Content does not imply approval
        or endorsement thereof by us. If you decide to leave the DTMEDIA Website
        and access the Third-Party Websites or to use or install any Third-Party
        Content, you do so at your own risk, and you should be aware these Terms
        and Conditions no longer govern.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        You should review the applicable terms and policies, including privacy
        and data gathering practices, of any website to which you navigate from
        the DTMEDIA Website or relating to any applications you use or install
        from the DTMEDIA Website. Any purchases you make through Third-Party
        Websites will be through other websites and from other companies, and we
        take no responsibility whatsoever in relation to such purchases which
        are exclusively between you and the applicable third party.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        You agree and acknowledge that we do not endorse the products or
        services offered on Third- Party Websites and you shall hold us harmless
        from any harm caused by your purchase of such products or services.
        Additionally, you shall hold us harmless from any losses sustained by
        you or harm caused to you relating to or resulting in any way from any
        Third-Party Content or any contact with Third-Party Websites.
      </p>{' '}
      <br />
      <div className={`${classes.tosTitle} font-400`}>ADVERTISERS </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We allow advertisers to display their advertisements and other
        information in certain areas of the DTMEDIA Website, such as sidebar
        advertisements or banner advertisements. If you are an advertiser, you
        shall take full responsibility for any advertisements you place on the
        DTMEDIA Website and any services provided on the DTMEDIA Website or
        products sold through those advertisements.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Further, as an advertiser, you warrant and represent that you possess
        all rights and authority to place advertisements on the DTMEDIA Website,
        including, but not limited to, intellectual property rights, publicity
        rights, and contractual rights.
      </p>{' '}
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        [As an advertiser, you agree that such advertisements are subject to our
        Digital Millennium Copyright Act (“DMCA”) Notice and Policy provisions
        as described below, and you understand and agree there will be no refund
        or other compensation for DMCA takedown-related issues.] We simply
        provide the space to place such advertisements, and we have no other
        relationship with advertisers.
      </p>{' '}
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        DTMEDIA WEBSITE MANAGEMENT{' '}
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        We reserve the right, but not the obligation, to:
      </p>{' '}
      <br />
      <div className={classes.padded}>
        <ol>
          <li>
            monitor the DTMEDIA Website for violations of these Terms and
            Conditions;
          </li>

          <li>
            take appropriate legal action against anyone who, in our sole
            discretion, violates the law or these Terms and Conditions,
            including without limitation, reporting such user to law enforcement
            authorities;
          </li>

          <li>
            in our sole discretion and without limitation, refuse, restrict
            access to, limit the availability of, or disable (to the extent
            technologically feasible) any of your Contributions or any portion
            thereof;
          </li>

          <li>
            in our sole discretion and without limitation, notice, or liability,
            to remove from the DTMEDIA Website or otherwise disable all files
            and content that are excessive in size or are in any way burdensome
            to our systems;
          </li>

          <li>
            otherwise manage the DTMEDIA Website in a manner designed to protect
            our rights and property and to facilitate the proper functioning of
            the DTMEDIA Website.
          </li>
        </ol>
      </div>
      <br />
      <div className={`${classes.tosTitle} font-400`}>PRIVACY POLICY</div>{' '}
      <p className={`${classes.tosMainTitle_p} `}>
        We care about data privacy and security. Please review our Privacy
        Policy [CLICK HERE]/posted on the DTMEDIA Website]. By using the DTMEDIA
        Website, you agree to be bound by our Privacy Policy, which is
        incorporated into these Terms and Conditions. Please be advised the
        DTMEDIA Website is hosted in the Republic of Namibia.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        If you access the DTMEDIA Website from the European Union, Asia, or any
        other region of the world with laws or other requirements governing
        personal data collection, use, or disclosure that differ from applicable
        laws in the Republic of Namibia, then through your continued use of the
        DTMEDIA Website, you are transferring your data to the Republic of
        Namibia, and you expressly consent to have your data transferred to and
        processed in the Republic of Namibia.{' '}
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        COPYRIGHT INFRINGEMENT AND POLICY
      </div>{' '}
      <p className={`${classes.tosMainTitle_p} `}>
        We respect the intellectual property rights of others. If you believe
        that any material available on or through the DTMEDIA Website infringes
        upon any copyright you own or control, please immediately notify using
        the contact information provided below (a “Notification”).{' '}
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        A copy of your Notification will be sent to the person who posted or
        stored the material addressed in the Notification. Please be advised
        that pursuant to federal law you may be held liable for damages if you
        make material misrepresentations in a Notification. Thus, if you are not
        sure that material located on or linked to by the DTMEDIA Website
        infringes your copyright, you should consider first contacting an
        attorney.{' '}
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        TERM AND TERMINATION{' '}
      </div>{' '}
      <p className={`${classes.tosMainTitle_p} `}>
        These Terms and Conditions shall remain in full force and effect while
        you use the DTMEDIA W ebsite. W ITHOUT LIMITING ANY OTHER PROVISION OF
        THESE TERMS AND CONDITIONS, WE RESERVE THE RIGHT TO, IN OUR SOLE
        DISCRETION AND WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE OF
        THE DTMEDIA WEBSITE (INCLUDING BLOCKING CERTAIN IP ADDRESSES), TO ANY
        PERSON FOR ANY REASON OR FOR NO REASON, INCLUDING WITHOUT LIMITATION FOR
        BREACH OF ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED IN THESE
        TERMS AND CONDITIONS OR OF ANY APPLICABLE LAW OR REGULATION. WE MAY
        TERMINATE YOUR USE OR PARTICIPATION IN THE DTMEDIA WEBSITE OR DELETE
        [YOUR ACCOUNT AND] ANY CONTENT OR INFORMATION THAT YOU POSTED AT ANY
        TIME, WITHOUT WARNING, IN OUR SOLE DISCRETION.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        If we terminate or suspend your account for any reason, you are
        prohibited from registering and creating a new account under your name,
        a fake or borrowed name, or the name of any third party, even if you may
        be acting on behalf of the third party.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        In addition to terminating or suspending your account, we reserve the
        right to take appropriate legal action, including without limitation
        pursuing civil, criminal, and injunctive redress.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        MODIFICATIONS AND INTERRUPTIONS
      </div>{' '}
      <p className={`${classes.tosMainTitle_p} `}>
        We reserve the right to change, modify, or remove the contents of the
        DTMEDIA Website at any time or for any reason at our sole discretion
        without notice. However, we have no obligation to update any information
        on our DTMEDIA Website. We also reserve the right to modify or
        discontinue all or part of the DTMEDIA Website without notice at any
        time.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        We will not be liable to you or any third party for any modification,
        price change, suspension, or discontinuance of the DTMEDIA Website.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        We cannot guarantee the DTMEDIA Website will be available at all times.
        We may experience hardware, software, or other problems or need to
        perform maintenance related to the DTMEDIA Website, resulting in
        interruptions, delays, or errors.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        We reserve the right to change, revise, update, suspend, discontinue, or
        otherwise modify the DTMEDIA Website at any time or for any reason
        without notice to you. You agree that we have no liability whatsoever
        for any loss, damage, or inconvenience caused by your inability to
        access or use the DTMEDIA Website during any downtime or discontinuance
        of the DTMEDIA Website.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Nothing in these Terms and Conditions will be construed to obligate us
        to maintain and support the DTMEDIA Website or to supply any
        corrections, updates, or releases in connection therewith.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>GOVERNING LAW</div>
      <p className={`${classes.tosMainTitle_p} `}>
        These Terms and Conditions and your use of the DTMEDIA Website are
        governed by and construed in accordance with the laws of the Republic of
        Namibia applicable to agreements made and to be entirely performed
        within the Republic of Namibia.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>DISPUTE RESOLUTION</div>
      <p className={`${classes.tosMainTitle_p} `}>
        Any legal action of whatever nature brought by either you or us
        (collectively, the “Parties” and individually, a “Party”) shall be
        commenced or prosecuted in the Republic of Namibia and courts located in
        the Republic of Namibia.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>CORRECTIONS</div>
      <p className={`${classes.tosMainTitle_p} `}>
        There may be information on the DTMEDIA Website that contains
        typographical errors, inaccuracies, or omissions that may relate to the
        DTMEDIA Website, including descriptions, pricing, availability, and
        various other information. We reserve the right to correct any errors,
        inaccuracies, or omissions and to change or update the information on
        the DTMEDIA Website at any time, without prior notice.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>DISCLAIMER</div>
      <p className={`${classes.tosMainTitle_p} `}>
        THE DTMEDIA WEBSITE IS PROVIDED ON AN AS-IS AND AS-AVAILABLE BASIS. YOU
        AGREE THAT YOUR USE OF THE DTMEDIA WEBSITE AND OUR SERVICES WILL BE AT
        YOUR SOLE RISK. TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM ALL
        WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION WITH THE DTMEDIA WEBSITE
        AND YOUR USE THEREOF, INCLUDING, WITHOUT LIMITATION, THE IMPLIED
        WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
        NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR REPRESENTATIONS ABOUT THE
        ACCURACY OR COMPLETENESS OF THE DTMEDIA WEBSITE’S CONTENT OR THE CONTENT
        OF ANY WEBDTMEDIA WEBSITES LINKED TO THE DTMEDIA WEBSITE AND WE WILL
        ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1) ERRORS, MISTAKES, OR
        INACCURACIES OF CONTENT AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
        DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE
        OF THE DTMEDIA WEBSITE, (3) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR
        SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR FINANCIAL
        INFORMATION STORED THEREIN, (4) ANY INTERRUPTION OR CESSATION OF
        TRANSMISSION TO OR FROM THE DTMEDIA WEBSITE, (5) ANY BUGS, VIRUSES,
        TROJAN HORSES, OR THE LIKE WHICH MAY BE TRANSMITTED TO OR THROUGH THE
        DTMEDIA WEBSITE BY ANY THIRD PARTY, AND/OR (6) ANY ERRORS OR OMISSIONS
        IN ANY CONTENT AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND
        INCURRED AS A RESULT OF THE USE OF ANY CONTENT POSTED, TRANSMITTED, OR
        OTHERWISE MADE AVAILABLE VIA THE DTMEDIA WEBSITE. WE DO NOT WARRANT,
        ENDORSE, GUARANTEE, OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
        ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE DTMEDIA WEBSITE, ANY
        HYPERLINKED WEBDTMEDIA WEBSITE, OR ANY WEBDTMEDIA WEBSITE OR MOBILE
        APPLICATION FEATURED IN ANY BANNER OR OTHER ADVERTISING, AND WE WILL NOT
        BE A PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING ANY
        TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY PROVIDERS OF PRODUCTS OR
        SERVICES.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        AS WITH THE PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM OR IN
        ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST JUDGMENT AND EXERCISE CAUTION
        WHERE APPROPRIATE.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        LIMITATIONS OF LIABILITY
      </div>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR AGENTS BE LIABLE TO
        YOU OR ANY THIRD PARTY FOR ANY DIRECT, INDIRECT, CONSEQUENTIAL,
        EXEMPLARY, INCIDENTAL, SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST
        PROFIT, LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING FROM YOUR
        USE OF THE DTMEDIA WEBSITE, EVEN IF WE HAVE BEEN ADVISED OF THE
        POSSIBILITY OF SUCH DAMAGES.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>INDEMNIFICATION</div>
      <p className={`${classes.tosMainTitle_p} `}>
        You agree to defend, indemnify, and hold us harmless, including our
        subsidiaries, affiliates, and all of our respective officers, agents,
        partners, and employees, from and against any loss, damage, liability,
        claim, or demand, including reasonable attorneys’ fees and expenses,
        made by any third party due to or arising out of: (1) [your
        Contributions]; (2) use of the DTMEDIA Website; (3) breach of these
        Terms and Conditions; (4) any breach of your representations and
        warranties set forth in these Terms and Conditions; (5) your violation
        of the rights of a third party, including but not limited to
        intellectual property rights; or (6) any overt harmful act toward any
        other user of the DTMEDIA Website with whom you connected via the
        DTMEDIA Website.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        Notwithstanding the foregoing, we reserve the right, at your expense, to
        assume the exclusive defense and control of any matter for which you are
        required to indemnify us, and you agree to cooperate, at your expense,
        with our defense of such claims. We will use reasonable efforts to
        notify you of any such claim, action, or proceeding which is subject to
        this indemnification upon becoming aware of it.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>USER DATA</div>
      <p className={`${classes.tosMainTitle_p} `}>
        We will maintain certain data that you transmit to the DTMEDIA Website
        for the purpose of managing the DTMEDIA Website, as well as data
        relating to your use of the DTMEDIA Website. Although we perform regular
        routine backups of data, you are solely responsible for all data that
        you transmit or that relates to any activity you have undertaken using
        the DTMEDIA Website.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        You agree that we shall have no liability to you for any loss or
        corruption of any such data, and you hereby waive any right of action
        against us arising from any such loss or corruption of such data.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>
        ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND SIGNATURES
      </div>
      <p className={`${classes.tosMainTitle_p} `}>
        Visiting the DTMEDIA Website, sending us emails, and completing online
        forms constitute electronic communications. You consent to receive
        electronic communications, and you agree that all agreements, notices,
        disclosures, and other communications we provide to you electronically,
        via email and on the DTMEDIA Website, satisfy any legal requirement that
        such communication be in writing.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        YOU HEREBY AGREE TO THE USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS,
        AND OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF NOTICES, POLICIES, AND
        RECORDS OF TRANSACTIONS INITIATED OR COMPLETED BY US OR VIA THE DTMEDIA
        WEBSITE.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        You hereby waive any rights or requirements under any statutes,
        regulations, rules, ordinances, or other laws in any jurisdiction which
        require an original signature or delivery or retention of non-
        electronic records, or to payments or the granting of credits by any
        means other than electronic means.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>MISCELLANEOUS </div>
      <p className={`${classes.tosMainTitle_p} `}>
        These Terms and Conditions and any policies or operating rules posted by
        us on the DTMEDIA Website constitute the entire agreement and
        understanding between you and us. Our failure to exercise or enforce any
        right or provision of these Terms and Conditions shall not operate as a
        waiver of such right or provision.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        These Terms and Conditions operate to the fullest extent permissible by
        law. We may assign any or all of our rights and obligations to others at
        any time. We shall not be responsible or liable for any loss, damage,
        delay, or failure to act caused by any cause beyond our reasonable
        control.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        If any provision or part of a provision of these Terms and Conditions is
        determined to be unlawful, void, or unenforceable, that provision or
        part of the provision is deemed severable from these Terms and
        Conditions and does not affect the validity and enforceability of any
        remaining provisions.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        There is no joint venture, partnership, employment or agency
        relationship created between you and us as a result of these Terms and
        Conditions or use of the DTMEDIA Website. You agree that these Terms and
        Conditions will not be construed against us by virtue of having drafted
        them.
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>
        You hereby waive any and all defenses you may have based on the
        electronic form of these Terms and Conditions and the lack of signing by
        the parties hereto to execute these Terms and Conditions.
      </p>
      <br />
      <div className={`${classes.tosTitle} font-400`}>CONTACT US</div>
      <p className={`${classes.tosMainTitle_p} `}>
        In order to resolve a complaint regarding the DTMEDIA Website or to
        receive further information regarding use of the DTMEDIA Website, please
        contact us at:
      </p>
      <br />
      <p className={`${classes.tosMainTitle_p} `}>DTMEDIA</p>
      <p className={`${classes.tosMainTitle_p} `}>DAVID TIBOTH</p>
      <p className={`${classes.tosMainTitle_p} `}>CHIEF EXECUTIVE OFFICER</p>
      <p className={`${classes.tosMainTitle_p} `}>
        WINDHOEK, REPUBLIC OF NAMIBIA
      </p>
      <p className={`${classes.tosMainTitle_p} `}>+264 81 27 19019</p>
      <p className={`${classes.tosMainTitle_p} `}>
        <a href='mailto:david@dtmedia.com.na'>david@dtmedia.com.na</a>
      </p>
    </div>
  );
};

export default TOS;
