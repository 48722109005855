import React from 'react';
import { useMyContext } from '../../../../contexts/StateHolder';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import * as classes from './EventsItem1.module.css';
import Header from '../../../../Shared/Header/Header';
import ItemLink from '../../../../Shared/ItemLink/ItemLink';
import { getImageByKey } from '../../../../scripts/getImageByKey';
import { Loader } from 'react-loader-spinner';

import Carousel from 'react-multi-carousel';
import useWindowDimensions from '../../../WindowDimension';
import { momentDate } from '../../../../scripts/utils';

/// this is ffor salibandy tv

export default function EventsItem1({
  allEvents,
  organizationId,
  categoryId,

  hideViewAll,
  upcomingEventCategory,
  extraClassname,
  heading,
  hidetitle,
  styles,
  accessedbyAdmin,
  settings,
  hideCarousel,
}) {
  const { slickSettings } = settings;
  console.log(settings, 'settings', accessedbyAdmin, allEvents);
  const { setSingleEventItem } = useMyContext();
  const { t } = useTranslation();

  const history = useHistory();

  // Destructure props.settings

  const clickhandler = (el) => {
    console.log(el);
    setSingleEventItem(el);

    // we will redirect to different page if they are clicked from admin page

    if (accessedbyAdmin) {
      history.push(`/admin/event/${organizationId}/${el.eventId}`);
    } else {
      history.push(`/event/${organizationId}/${el.eventId}`);
    }
  };
  let className1 = '';
  const windowDimension = useWindowDimensions();

  if (allEvents?.length > 0 && slickSettings) {
    let arr = Object.keys(slickSettings.responsive).map(
      (k) => slickSettings.responsive[k]
    );
    let arr1 = [...arr];

    let j = [...arr1].find(
      (el) =>
        el.breakpoint.min <= windowDimension.width &&
        el.breakpoint.max >= windowDimension.width
    );

    if (allEvents?.length === j?.items) {
      className1 = 'addSeecondary';
    } else {
      className1 = '';
    }
  }

  const SHOW_ONLY_DATE = true;

  console.log('allEvents', allEvents);

  const data =
    allEvents?.length > 0 &&
    allEvents.map((el, i) => {
      return (
        <div
          key={el?.eventId ? el?.eventId : el + i}
          className={classes.eventsItem1_card}
          onClick={() => clickhandler(el)}
        >
          <div className={classes.eventsItem1_primary}>
            {' '}
            <div className={classes.eventsItem1_heading}>
              <div className={classes.eventsItem1_heading_date}>
                {el.startTimePublic
                  ? momentDate(el.startTimePublic, SHOW_ONLY_DATE)
                  : momentDate(el.startTime, SHOW_ONLY_DATE)}
              </div>
            </div>
            <div className={classes.eventsItem1_image}>
              <img
                src='https://fliigatv.com/image/image_gallery?img_id=113124609'
                alt=''
              />
              <img
                src='https://fliigatv.com/image/image_gallery?img_id=113124609'
                alt=''
              />
            </div>
          </div>
          <div className={classes.eventsItem1_secondary}>
            <div className={classes.eventsItem1_text}>
              {el.publicName[el.defaultLanguage]
                ? el.publicName[el.defaultLanguage]
                : el.name[el.defaultLanguage] &&
                  el.name.en_US.replace(/<[^>]*>?/gm, '')}
            </div>
          </div>
        </div>
      );
    });
  return allEvents ? (
    <div className={`${className1}`}>
      {heading && (
        <div className={classes.Events_main_info}>
          {!hidetitle && (
            <Header
              extraClassname={extraClassname}
              title={heading}
              showTitle={true}
              admin={accessedbyAdmin}
            />
          )}

          {!hideViewAll &&
            (allEvents.length > 0 && upcomingEventCategory ? (
              <>
                <ItemLink
                  link={true}
                  route={
                    heading === t('eventsCategory.upcomingEventsTitle')
                      ? `/events/Upcoming Events/${organizationId}/upcomingEvent`
                      : `/events/Live Now Events/${organizationId}/liveEvent`
                  }
                />
              </>
            ) : (
              allEvents.length > 0 && (
                <ItemLink
                  route={`/events/${heading}/${organizationId}/${categoryId}`}
                  link={true}
                />
              )
            ))}
        </div>
      )}

      {allEvents.length > 0 ? (
        <div
          className={`${classes.eventsItem1} ${
            accessedbyAdmin && classes.eventsItem2
          } eventsItemCarousel`}
        >
          {accessedbyAdmin ? (
            allEvents.map((el, i) => {
              return (
                <div
                  key={el?.eventId ? el?.eventId : el + i}
                  className={classes.eventsItem1_card_admin}
                  onClick={() => clickhandler(el)}
                >
                  <div className={classes.eventsItem1_primary_admin}>
                    {' '}
                    <div className={classes.eventsItem1_heading}>
                      <div className={classes.eventsItem1_heading_name}>
                        series name
                      </div>
                      <div>|</div>
                      <div className={classes.eventsItem1_heading_date}>
                        {el.startTimePublic
                          ? momentDate(el.startTimePublic, SHOW_ONLY_DATE)
                          : momentDate(el.startTime, SHOW_ONLY_DATE)}
                      </div>
                    </div>
                    <div className={classes.eventsItem1_image}>
                      <img
                        src='https://fliigatv.com/image/image_gallery?img_id=113124609'
                        alt=''
                      />
                      <img
                        src='https://fliigatv.com/image/image_gallery?img_id=113124609'
                        alt=''
                      />
                    </div>
                  </div>
                  <div className={classes.eventsItem1_secondary}>
                    <div className={classes.eventsItem1_text}>
                      {el.publicName[el.defaultLanguage]
                        ? el.publicName[el.defaultLanguage]
                        : el.name[el.defaultLanguage] &&
                          el.name.en_US.replace(/<[^>]*>?/gm, '')}
                    </div>
                  </div>
                </div>
              );
            })
          ) : hideCarousel ? (
            <div className={classes.eventsItem1_hideCarousel}> {data}</div>
          ) : (
            <Carousel {...slickSettings}>{data}</Carousel>
          )}
        </div>
      ) : (
        <div className={classes.NoDataAvailable}>
          <Header title={t('eventsCategory.noEvents')} />
          <img
            src={getImageByKey('no_data_available')}
            alt='no_data_available'
          />
        </div>
      )}
    </div>
  ) : (
    <div className='display-flex-center'>
      {' '}
      <Loader type='TailSpin' color='#161eaf' />{' '}
    </div>
  );
}
