import { useTranslation } from 'react-i18next';
import { useMyContext } from '../../../contexts/StateHolder';
import PackageComponent from '../PackageComponent/PackageComponent';
import * as classes from './PackagesView.module.css';
import { useForm } from '../../../Shared/Hooks/form-hook';
import {
  VALIDATOR_EMAIL,
  VALIDATOR_PASSWORDMATCH,
  VALIDATOR_REQUIRE,
} from '../../../Shared/Validation/Validator';
import { Input } from '../../../Shared/Input/Input';
import { useCookies } from 'react-cookie';
import { useState, useEffect } from 'react';
import { getUser } from '../../../scripts/dataHandlers';

//prints title and all the packages
const PackagesView = (props) => {
  const { buyButtonAction, profileData } = props;
  const { packages, user, organizationId } = useMyContext();
  const [cookies, setCookie] = useCookies(['ue']);

  const [loading, setLoading] = useState(true);

  const [userBoughtProduct, setUserBoughtProduct] = useState([]);

  const { t } = useTranslation();

  const [state, InputHandler] = useForm(
    {
      EMAIL: {
        value: '',
        isValid: false,
      },
    },
    false
    // the last false defines if the whole form is valid or not ( since we have set all isvalid to false so our total form validity will also be false)
  );
  console.log(cookies.ue, 'cookiepackage ', user);

  useEffect(() => {
    // we will run this when user logged in status changes
    if (user?.loggedIn) {
      async function getUserData() {
        try {
          const response = await getUser(user?.userToken, organizationId);

          if (response.status === 200) {
            const newData = {
              firstName: response.data.firstName,
              lastName: response.data.lastName,
              phone: response.data.phone,
              email: response.data.emailAddress,
              city: response.data.city,
              country: response.data.country,
              countryId: response.data.countryId,
              address: response.data.address,
              postalCode: response.data.postalCode,
              regionId: response.data.regionId,
              buyerProducts: response.data.buyerProducts,
            };
            console.log('newData', newData);
            setUserBoughtProduct(newData?.buyerProducts);
            setLoading(false);
          } else {
            console.log('something wrong with request');
            setLoading(false);
          }
        } catch (err) {
          console.log(err);
          setLoading(false);
        }
      }

      setLoading(true);
      getUserData();
    } else {
      setUserBoughtProduct([]);
      setLoading(false);
    }
  }, [user?.loggedIn, organizationId, user?.userToken]);

  return (
    <div className={classes.packagesView}>
      <div className={classes.packageHelp}>
        <div className={classes.packageHelpTitle}>
          {t('packages.PackageHelpTitle')}
        </div>
        <div className={classes.packageHelpText}>
          {t('packages.PackageHelpText')}
          <br />
          <br />
          {t('packages.PackageHelpText2')}
          <br />
          <br />

          {!user?.loggedIn ? (
            <strong>{t('packages.PackageLoginForAccess')}</strong>
          ) : (
            <strong>{t('packages.PackageHelpEmail')}</strong>
          )}

          {user?.loggedIn && user.eMail && (
            <>
              <div className={classes.packageHelp_input}>
                <Input
                  id='EMAIL'
                  label='EMAIL'
                  placeholder={'ENTER YOUR EMAIL'}
                  type='text'
                  element='input'
                  validators={[VALIDATOR_REQUIRE(), VALIDATOR_EMAIL()]}
                  // errorText={'please enter valid email'}
                  onInput={InputHandler}
                  iconName='envelope'
                  style={{ margin: '0' }}
                  initialValue={user?.eMail}
                  initialValid={user?.eMail ? true : false}
                  disabled={true}
                />
              </div>
            </>
          )}
        </div>
      </div>
      {!loading && userBoughtProduct && (
        <div key='packages' className={classes.packages}>
          {Object.values(packages).map((value) => (
            <PackageComponent
              key={value.id}
              pkg={value}
              buyButtonAction={buyButtonAction}
              userPackages={userBoughtProduct}
              isValid={state.inputs.EMAIL.isValid}
              email={state.inputs.EMAIL.value}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default PackagesView;
